export default {
  AFILIATES: {
    GBEST: {
      id: 'gbest',
      label: 'www.gbest.com',
      url: 'https://www.gbest.com/discount/OVERLAYON',
    },
    ETORO: {
      id: 'etoro',
      label: 'www.etoro.com',
      url: 'https://etoro.tw/3DF2lJW',
    },
    FIVERR: {
      id: 'fiverr',
      label: 'www.fiverr.com',
      url: 'https://go.fiverr.com/visit/?bta=644151&brand=fiverrcpa',
    },
    REVOLUT: {
      id: 'revolut',
      label: 'www.revolut.com',
      url: 'https://revolut.com/referral/?referral-code=mikelso7!AUG1-23-AR-H1',
    },
    LETYSHOPS: {
      id: 'letyshops',
      label: 'www.letyshops.com',
      url: 'https://letyshops.com/es/winwin?ww=9378254',
    },
    // COINBASE: 'https://www.coinbase.com/join/5568d6215c6e772e19000020',
  },

  DEFAULT_BANNER:
    'https://overlayon-production.s3.eu-west-3.amazonaws.com/default-banner.png',

  MAX_FILE_SIZE: 10 * 1000 * 1000,

  RPM: 3,
  CPC: 0.12,

  CURRENCY: {
    AD: 'eur',
    ES: 'eur',
    IT: 'eur',
    PT: 'eur',
    FR: 'eur',
    DE: 'eur',
    MX: 'mxn',
    CL: 'clp',
    PE: 'pen',
    AR: 'usd',
    US: 'usd',
  },

  USERNAME_REGEX: /^[a-zA-Z0-9_-]+$/,
};
