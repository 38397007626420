import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18n-js';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { AnalyticsProvider } from '../../../../providers';
import NotificationCampaignImage from './noticiation-campaign.png';
import NotificationGbestImage from './notification-gbest.png';
import Notification1Image from './notification1.png';
import Notification2Image from './notification2.png';
import { Header, Title, useStyles } from './styles';

const getNotifications = () => [
  {
    id: 'soccer-worldCup2022',
    avatar: Notification2Image,
    title: i18n.t('components.notifications.title2'),
    description: i18n.t('components.notifications.description2'),
    url: '/template/soccer',
  },
  {
    id: 'overlayon-campaigns',
    avatar: NotificationCampaignImage,
    title: i18n.t('components.notifications.title4'),
    description: i18n.t('components.notifications.description4'),
    url: '/revenue',
  },
  {
    id: 'gbest-referral',
    avatar: NotificationGbestImage,
    title: i18n.t('components.notifications.title3'),
    description: i18n.t('components.notifications.description3'),
    externalUrl: 'https://www.gbest.com/discount/OVERLAYON',
  },
  {
    id: 'quiz-simple',
    avatar: Notification1Image,
    title: i18n.t('components.notifications.title1'),
    description: i18n.t('components.notifications.description1'),
    url: '/template/quiz',
  },
];
const getStoredNotifications = () => {
  const storedNotifications = localStorage.getItem('notifications') || '';
  return storedNotifications.split(',');
};

function Notifications() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const classes = useStyles();
  const NOTIFICATIONS = React.useMemo(getNotifications, []);
  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;
  const storedNotifications = getStoredNotifications();
  const unReadNotifications = NOTIFICATIONS.reduce(
    (previousValue, currentValue) =>
      previousValue + (storedNotifications.includes(currentValue.id) ? 0 : 1),
    0
  );

  const handleClick = (event) => {
    AnalyticsProvider.event('CLICK_NOTIFICATION_ICON', { id });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickNotification = (id, url, externalUrl) => {
    if (!storedNotifications.includes(id)) {
      storedNotifications.push(id);
      localStorage.setItem('notifications', storedNotifications);
    }
    AnalyticsProvider.event('CLICK_NOTIFICATION', { id });
    if (url) history.push(url);
    if (externalUrl) window.open(externalUrl);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label={`show ${unReadNotifications} new notifications`}
        color="inherit"
        onClick={handleClick}
        size="large"
      >
        <Badge badgeContent={unReadNotifications} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Header>
          <Title>
            <Typography variant="h6">
              {i18n.t('components.notifications.title')}
            </Typography>
          </Title>
          <IconButton aria-label="close" onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Header>
        <Divider />
        <List className={classes.root}>
          {NOTIFICATIONS.map((notification) => (
            <React.Fragment key={notification.id}>
              <ListItem
                alignItems="flex-start"
                className={classes.list}
                onClick={() =>
                  onClickNotification(
                    notification.id,
                    notification.url,
                    notification.externalUrl
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={notification.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={notification.title}
                  secondary={notification.description}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Popover>
    </>
  );
}

export default Notifications;
