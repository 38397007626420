import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import validate from 'validate.js';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import CONSTRAINTS from '../../../../constants/constraints';
import { AnalyticsProvider } from '../../../../providers';
import { authSuccess, setSignUp } from '../../../../reducers/Auth/actions';
import Loading from '../../../Loading';
import { CheckPass } from './components';
import {
  Container,
  Form,
  FormControlLabelStyled,
  Header,
  TermsLink,
  useStyles,
} from './styles';

const { getSignupConstraints } = CONSTRAINTS;

export default function SignupDialog({ onClose, onLogin, onSuccess, open }) {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [allowExtraEmails, setAllowExtraEmails] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [confirmTermsError, setConfirmTermsError] = useState(null);
  const classes = useStyles();

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onCloseDialog = () => {
    setUsername('');
    setUsernameError(null);
    setPassword('');
    setPasswordError(null);
    setConfirmPassword('');
    setConfirmPasswordError(null);
    setEmail('');
    setEmailError(null);
    setAllowExtraEmails(false);
    setConfirmTerms(false);
    setConfirmTermsError(null);
    onClose();
  };
  const onSuccessSignup = () => {
    onCloseDialog();
    onSuccess();
  };
  const onSubmit = (event) => {
    event.preventDefault();

    setUsernameError(null);
    setPasswordError(null);
    setConfirmPasswordError(null);
    setEmailError(null);
    setConfirmTermsError(null);

    const data = {
      allowExtraEmails,
      confirmTerms,
      confirmPassword,
      email,
      password,
      referral: localStorage.getItem('referral'),
      username,
    };
    const errors = validate(data, getSignupConstraints());
    if (errors && Object.keys(errors).length > 0) {
      setUsernameError(errors.username?.[0]);
      setPasswordError(errors.password?.[0]);
      setConfirmPasswordError(errors.confirmPassword?.[0]);
      setEmailError(errors.email?.[0]);
      setConfirmTermsError(errors.confirmTerms?.[0]);
    } else {
      dispatch(setSignUp(data, onSuccessSignup));
      localStorage.removeItem('referral');
      AnalyticsProvider.event('SIGNUP', {
        username,
        email,
        referral: data.referral,
        allowExtraEmails,
      });
    }
  };
  const handleClose = () => {
    dispatch(authSuccess);
  };
  const onClickLogin = () => {
    onCloseDialog();
    onLogin();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth="md">
      <IconButton
        aria-label="close"
        onClick={onCloseDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Loading isOpen={auth.loading} />
        <Container>
          <Header>
            <Typography variant="h4">
              {i18n.t('screens.signup.title')}
            </Typography>
            {auth.error && (
              <Alert
                className={classes.alert}
                elevation={6}
                icon={false}
                onClose={handleClose}
                severity="error"
                variant="filled"
              >
                {auth.error}
              </Alert>
            )}
          </Header>
          <Form>
            <Typography
              className={classes.required}
              mt={2}
              color="text.secondary"
              variant="caption"
            >
              <strong>* </strong>
              {i18n.t('screens.signup.requiredField')}
            </Typography>
            <form noValidate>
              <TextField
                autoComplete="username"
                autoFocus
                fullWidth
                error={!!usernameError}
                helperText={usernameError}
                label={i18n.t('screens.signup.username')}
                margin="normal"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                required
                value={username}
                variant="outlined"
              />
              <TextField
                autoComplete="new-password"
                fullWidth
                error={!!passwordError}
                helperText={passwordError}
                label={i18n.t('screens.signup.password')}
                margin="normal"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                type="password"
                value={password}
                variant="outlined"
              />
              <Grid container>
                <Grid item xs={6}>
                  <CheckPass
                    hasPasswordError={!!passwordError}
                    isCorrect={/(?=.*[A-Z])/.test(password)}
                    label={i18n.t('screens.signup.checkUppercase')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CheckPass
                    hasPasswordError={!!passwordError}
                    isCorrect={/(?=.*\d)/.test(password)}
                    label={i18n.t('screens.signup.checkNumber')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CheckPass
                    hasPasswordError={!!passwordError}
                    isCorrect={/(?=.*[a-z])/.test(password)}
                    label={i18n.t('screens.signup.checkLowercase')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CheckPass
                    hasPasswordError={!!passwordError}
                    isCorrect={/[a-zA-Z0-9]{8}/.test(password)}
                    label={i18n.t('screens.signup.checkLength')}
                  />
                </Grid>
              </Grid>
              <TextField
                autoComplete="new-password"
                fullWidth
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                label={i18n.t('screens.signup.confirmPassword')}
                margin="normal"
                name="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                type="password"
                value={confirmPassword}
                variant="outlined"
              />
              <TextField
                autoComplete="email"
                fullWidth
                error={!!emailError}
                helperText={emailError}
                label={i18n.t('screens.signup.email')}
                margin="normal"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
                value={email}
                variant="outlined"
              />
              <FormControlLabelStyled
                $hasError={!!confirmTermsError}
                control={
                  <Checkbox
                    color="primary"
                    checked={confirmTerms}
                    onChange={(e) => setConfirmTerms(e.target.checked)}
                    value="confirmTerms"
                  />
                }
                label={
                  <span>
                    {i18n.t('screens.signup.terms1')}{' '}
                    <TermsLink
                      href={`${process.env.REACT_APP_LANDING_URL}/terms-and-conditions`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i18n.t('screens.signup.terms2')}
                    </TermsLink>
                  </span>
                }
              />
              <FormControlLabelStyled
                control={
                  <Checkbox
                    color="primary"
                    checked={allowExtraEmails}
                    onChange={(e) => setAllowExtraEmails(e.target.checked)}
                    value="allowExtraEmails"
                  />
                }
                label={i18n.t('screens.signup.marketing')}
              />
              <Button
                className={classes.submit}
                color="primary"
                disabled={auth.loading}
                fullWidth
                onClick={onSubmit}
                type="submit"
                variant="contained"
              >
                {i18n.t('screens.signup.cta')}
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <div className={classes.link} onClick={onClickLogin}>
                    {i18n.t('screens.signup.signin')}{' '}
                    <strong>{i18n.t('screens.signup.signin2')}</strong>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Form>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
