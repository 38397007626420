import styled from 'styled-components';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;
Container.displayName = 'Container';

export const CheckCircle = styled(CheckCircleTwoToneIcon)`
  color: ${({ $fontColor }) => $fontColor};
  font-size: 16px;
  margin-right: 8px;
`;
CheckCircle.displayName = 'CheckCircle';

export const Title = styled.p`
  color: ${({ $fontColor }) => $fontColor};
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0;
`;
Title.displayName = 'Title';
