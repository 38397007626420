import React from 'react';
import PropTypes from 'prop-types';

import { Container, CheckCircle, Title } from './styles';

function CheckPass({ hasPasswordError, isCorrect, label }) {
  const color = isCorrect
    ? '#70C1B3'
    : hasPasswordError
    ? '#FF7979'
    : '#b4bacf';

  return (
    <Container>
      <CheckCircle $fontColor={color} />
      <Title $fontColor={color}>{label}</Title>
    </Container>
  );
}

CheckPass.propTypes = {
  hasPasswordError: PropTypes.bool,
  isCorrect: PropTypes.bool,
  label: PropTypes.string,
};

CheckPass.defaultProps = {
  hasPasswordError: false,
  isCorrect: false,
  label: '',
};

export default CheckPass;
