import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchDelete = async (url) => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const { data } = await instance.delete(url, { headers });

  return data;
};

export const fetchFiles = async (url, files) => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  };
  let formData = new FormData();
  Array.from(files).forEach((file) => {
    formData.append('uploaded_files', file);
  });
  const { data } = await instance.post(url, formData, { headers });

  return data;
};

export const fetchGet = async (url) => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const { data } = await instance.get(url, { headers });

  return data;
};

export const fetchGetOutside = async (url) => {
  const { data } = await axios.get(url);

  return data;
};

export const fetchPost = async (url, params) => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const { data } = await instance.post(url, params, { headers });

  return data;
};

export const fetchPut = async (url, params) => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const { data } = await instance.put(url, params, { headers });

  return data;
};

export default {
  fetchDelete,
  fetchFiles,
  fetchGet,
  fetchGetOutside,
  fetchPost,
  fetchPut,
};
